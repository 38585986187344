/* Drop Downs */

header {
	/*Icon should be white when there is a notification*/
	a.hasnotifications {color:#fff !important;}

	.toolbar .dropdown, #headerbardropdown, #rightmenu-trigger {
		border-left: 1px solid @navbar-vertical-separator;
	}
	#leftmenu-trigger {
		border-right: 1px solid @navbar-vertical-separator;
	}
}

.scrollthis {
	height: 200px; overflow-y: scroll;
	li {
	     .clearfix();
	     a {display: block; text-decoration: none;}
	}
	+ .dd-footer {border-top: 1px solid #e6e7e8;}
}

.dropdown-menu {
	&.notifications, &.messages {padding: 0; z-index: 1300;}
	&.notifications {width: 292px;}

	&.notifications li, &.messages li {
		border-bottom: 1px solid #e6e7e8;
		color: #000 !important;
		a {
			white-space: normal; color: #4D4D4D;
			&.active, &:hover {color: #1a1a1a !important; background: #f7f8fa !important}
		}
		.time {color: @grayed;font-size: 0.85em; white-space: nowrap; text-align: right; vertical-align: top}

		&.dd-header a, &.dd-footer a {color: @link-color;}
		&.dd-header {
			padding: 6px 10px; .clearfix; font-size: 0.85em;
			span:first-child {.pull-left; font-weight: 400;}
			span:last-child {
				.pull-right;
				a {padding: 0 !important;}
			}
			a {background: none !important}
			a:hover {color: @link-hover-color}
		}
		&.dd-footer {
			font-size: 0.85em;
			a {text-align: center; padding: 7px 10px;}
		}
		&:last-child {border-bottom: 0;}
	}

	&.notifications li {
		.notification-user i 	{background-color: @brand-midnightblue;}
		.notification-warning i {background-color: @brand-warning;}
		.notification-danger i 	{background-color: @brand-danger;}
		.notification-fix i 	{background-color: @brand-inverse;}
		.notification-success i {background-color: @brand-success;}
		.notification-failure i {background-color: @brand-danger;}
		.notification-order i 	{background-color: @brand-info;}
		a {
			padding: 12px 15px; 
			line-height: 16px;
			i {
				.pull-left;
				color: rgba(255, 255, 255, 1); padding: 3px 0; min-width: 20px; text-align: center; margin-top: -2px; border-radius: @border-radius-small;
			}
			.msg {overflow: hidden; padding: 0 7px; display: inline; width: 210px;}
		}
		.time {float: right;}
	}

	&.messages li {
		a {
			width: 290px; line-height: 14px;
			img {.pull-left; margin-right: 10px; height: 30px; border-radius: @border-radius-small;}
			div {overflow: hidden; position: relative;}

			padding: 15px 15px 12px;
			.name {margin-top: 0px;} .msg {padding-top: 5px;}
		}
		.time {float: right;}
		img {height:30px;width:30px;}
		span {display: block;}
		span.msg {font-size: 0.85em; color: @grayed2;}
	}

	&.userinfo {
		padding: 15px 15px 0;
		min-width: 200px;
		.clearfix();
		ul {padding-left: 0;}
		li {list-style:none;}
		h5 {margin: -2px 0 -2px 0;}

		li.username {
			.clearfix();
			margin: 0 -15px;
			padding: 0 10px 10px;
			border-bottom: 1px solid @border-darker;

			a {padding: 0 5px;}

			.pull-left img {width: 30px; border-radius: @border-radius-small;}
			.pull-right {
				min-width: 128px;
				small {
					color: @grayed2;
					span {color: @link-color}
				}
			}
		}

		.userlinks {
			ul {
				display: block;
				position: relative;
				border:none;
				background: transparent;
				width: 100%;
				.box-shadow(~"none");

				li {
					margin: 0 -15px;
					a {white-space: inherit;}
					&.divider {margin: 5px -15px;}
				}

				i.pull-right {margin-top:2px;}
			}
		}
	}
	&.search {
		width: 280px;
		padding: 0;
		input[type="text"] {border:none;height: 38px;}
	}
}

#headerbardropdown {
	padding: 7px 12px;
	span {background-color: rgba(255,255,255,0.15); padding: 2px 8px 3px 8px; display: block;border-radius:@border-radius-small}
	i {margin-left: 0px;}
	&:hover span {background-color: rgba(255,255,255,0.25);}
}

.toolbar.navbar-nav           {margin: 0;}
.navbar-nav .dropdown-toggle  {padding-top: 10px; padding-bottom: 10px;font-size:14px;}
.navbar-nav.toolbar>li        {float: right;}


a.username {
	padding: 8px 10px !important;
	font-size: 12px !important;
	.clearfix();
	span {
		padding-right: 10px;
	    //Visual Alignment
	    top:2px;
	    position: relative;
	    float: left;
	}
	img {
		height: 24px;
		width: 24px;
		float: right;
		border-radius: @border-radius-small;
	}
}

.dropdown-menu.userinfo .username a {
	.clearfix();
	padding: 0;
	color: @gray-darker;
	&:hover {text-decoration: none;background: none;}
}

header.navbar {margin-bottom:0px; border:none;}

/* Nav and Sidebar Badges
// ------------------------*/

header .nav .badge, #sidebar .badge {
	text-align: center;
	text-shadow: none !important;
}

header .nav .badge {
	top: 4px;
	left: 22px;
	position: absolute;
	background-color: @brand-danger;
}

#sidebar .badge {
	float: right;
	position: absolute;
  	top: 11px;
  	right: 30px;
}

/* The toggle links for left/right sidebar */
.navbar .btn {font-size: 1em; padding: 5px;}
.navbar .btn.pull-left {border-right: 1px solid #555; margin-right: 10px;}
.navbar .btn.pull-right {border-left: 1px solid #555; margin-left: 10px}


/* Menu Triggers */
#leftmenu-trigger, #rightmenu-trigger {
	height: 40px; width: 40px; cursor: pointer;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RjI5OTJEN0UxMDdFMTFFMzk1QjZBODRFMTJGRERFNzMiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RjI5OTJEN0QxMDdFMTFFMzk1QjZBODRFMTJGRERFNzMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmRpZDo4QzE3NEM1ODdCMTBFMzExQTBDOEVEREIyRENFMUU0QSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo4QzE3NEM1ODdCMTBFMzExQTBDOEVEREIyRENFMUU0QSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PmxMBE8AAADNSURBVHja7NpBCoAwDARAIz7cn9cvaGhqW2bPCnEwSxGjtXZIPicCgAABAhSAAAECFIAAAQIUgADXyZW45+583aiUzB0+qFphgDpw7P1TzacDrTBAHTjxmbB8Vh1ohQECBCgAAQIEKAABAgQoAAECBCgAAQIEKAABAgQoAAECBCgAAU6YzP+Bb/+Hi8metWTuDGAs+rKEFdaBOvBLl/xVAUPn27EDwwrrQB3Yu2P+WvvyWXc/B4YV1oEABSBAgAAFIECAAAUgQIA75RFgAGBMGKmai6EfAAAAAElFTkSuQmCC);
}
#leftmenu-trigger {
	background-position: 0 0; float: left;
	&:hover {background-position: 0 40px;}
}
#rightmenu-trigger {
	background-position: 39px 0; float: right;
	&:hover {background-position: 39px 40px;}
}

@media (max-width: @screen-xs-max) {
	//Alignments in small screen
	.dropdown-menu.messages {
		margin-right: -35px;
		&:before {margin-right: 35px;}
	}
	.dropdown-menu.notifications {
		margin-right: -85px;
		&:before {margin-right: 85px;}
	}

	// Menu Horizontal
	nav.navbar .dropdown-menu {
		position: relative;
		float: none;
		background: none;
		.box-shadow(~"none");
		border:none;
	}
	nav.navbar .navbar-header {width: auto;}
}

nav.navbar {margin-bottom: 0;}


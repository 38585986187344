//popovers.less
.popover {
	.box-shadow(0 2px 10px rgba(0,0,0,.1));
}
.popover-title {
	padding: 8px 15px;
	border-bottom-width: 0;
	border-radius: 0;
}
.popover-content {
	padding: 10px 15px;
}

//tooltips
.tooltip {
	&.top    { margin-top:  -3px; padding: @tooltip-arrow-width 0; }
  	&.right  { margin-left:  3px; padding: 0 @tooltip-arrow-width; }
  	&.bottom { margin-top:   3px; padding: @tooltip-arrow-width 0; }
  	&.left   { margin-left: -3px; padding: 0 @tooltip-arrow-width; }
  	&.top-left .tooltip-arrow, &.bottom-left .tooltip-arrow {
  		left: @tooltip-arrow-width;
  	}
  	&.top-right .tooltip-arrow, &.bottom-right .tooltip-arrow {
  		right: @tooltip-arrow-width;
  	}
}
.tooltip-inner {
	padding: 6px 10px;
}
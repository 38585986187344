@avantPath: "../libs/less";

// Import des mixins et variables pour utilisation
@import "@{avantPath}/variables.less";
@import "@{avantPath}/avant-mixins.less";
@import "bootstrap-avanttheme/less/mixins";

table#catalogTable {
    border-color: @panel-primary-border;
    border-size : 1px;
    th {
        color: @panel-primary-text;
        background-color: @panel-primary-heading-bg;
        border-top:0;
        border-bottom:0;
    }

    background-color: @panel-bg;

    tr {
        border: none;
    }

    thead tr {
        height: 40px;
        border: none;

        th { height: 40px; line-height: 40px; padding: 0 5px;}

        ul.nav.nav-tabs {
          position: relative;
          > li {
              padding-left: 0;
              > a {
                padding: 0 10px;
                line-height: 40px;
                margin-top: 0px;
                color: #fff; //fallback
                color:rgba(255,255,255,0.75);
                outline: none;
                border:none;
              }

              > a:hover {color: #fff; background: none;}
              &.active a {
                color: #000;
                background-color: @panel-bg;
                border-left: 1px solid @border-darker;
                border-right: 1px solid @border-darker;
                border-top: 1px solid @border-darker;
              }
          }
          bottom: -1px;
          right: 9px;
          border:none;
        }
    }

    .repository-column {
        width: 250px;
    }
    td {
        padding: 0;
    }

    .list-group {
        margin: 0;
    }
    .list-group-item {
        border-left: 0;
        border-right: 0;
        cursor: pointer;
        padding: 0;
        height: 35px;
        line-height: 35px;
    }

    .sortable {
        list-style-type: none;
        margin: 0;
        padding: 0;
        color: #000;
    }
    .sortable li .handle {
        height: 100%;
        width : 20px;
        margin-right: 10px;
        text-align: center;
        background-color: #f2f2f2;
        border-right: 1px solid #c3c3c3;
        float: left;
        color: #c3c3c3;
        cursor: move;
    }
    .sortable-placeholder {
        border: 1px dashed red;
        height: 35px;
        margin: 5px;
        background: transparent;
    }

    .sortable li.active  {
        background-color: #444 ;
        color: #cacaca;
        font-weight: bold;
    }

    .sortable li.active  .handle {
        background: #444;
        color: #bababa;
    }

    .ancestor li.catalog-item:not(.active){
        opacity: 0.6;
    }

    .sortable li.catalog-item:not(.active):hover .handle{
        background-color: #fff;
    }

    .sortable li.catalog-item:not(.active):hover{
        background-color: #fff;
    }

    .ancestor li.catalog-item:not(.active):hover{
        opacity: 1;
    }

    #category_repository .disabled a, #product_repository .disabled a{
        cursor: default;
        color: #ccc;
    }

    .sortable .actions.btn-group > .btn, .sortable .actions.btn-group > .btn:hover {
        padding:  @padding-small-vertical @padding-small-horizontal ;
        border-top: 0px;
        border-bottom: 0px;
    }

    .actions {
        display: none;
    }

    .item-details {
        display: none;
    }

    .working .catalog-item:hover .actions {
        display: block;
    }

    .working .catalog-item:hover .item-details {
        display: inline;
    }
}

//
// Panels
// --------------------------------------------------

@line-height-tabs-default: 2.36em; //33/14
@line-height-tabs-color: 2.5em; //35/14
@line-height-panel: 2.86em; //40/14

// Base class
.panel {
  margin: 0px 0 20px;
  border-radius:  @panel-border-radius;
}

.panel-body {
  border: 1px solid @panel-inner-border;
  border-radius:  @panel-border-radius;
  .clearfix(); 
}

// Heading
.panel-heading {
  font-size: @font-size-large;
  // font-weight: 500;
  // background-color: @panel-default-heading-bg;

  border-bottom: 1px solid @panel-inner-border;
  .border-top-radius(@panel-border-radius);

  .clearfix();  
  height: 40px;
  line-height: 2em;

  .nav.nav-tabs li { // ALL the heading tabs
    a {color: #888;outline: none;}
    a:hover {border: 1px solid transparent; color: #444; background: none;}
    &.active a {
      color: #000;
      background-color: @body-bg;
      border-left: 1px solid @border-darker;
      border-right: 1px solid @border-darker;
      border-top: 1px solid @border-darker;
    }
  }

  
  h4 {
    float: left;
    margin: 0;
    line-height: 40px;
    i {position: relative;} //Align visually
    // max-width: 80%;
    // text-overflow: ellipsis;
    // overflow: hidden;
  }
  .options {
    .pull-right;
    .clearfix();

    .nav-tabs {
      float: left;
      position: relative;
      top: 5px;
      li a { line-height: @line-height-tabs-default; } // + 2px border = 35}
    }

    > a { // Links outside tabs
      .opacity(0.50); 
      color: #000; 
      font-size: 14px;
      float: right;
      line-height: 40px;
      padding-left: 10px;
      &:hover {
        .opacity(1); 
        text-decoration: none
      }
    }

    .btn {line-height: 1em !important; padding-top: 2px;padding-bottom: 2px; .opacity(1);}
  }
}

// Footer (stays gray in every modifier class)
.panel-footer {
  .clearfix();
  padding: 20px 20px;
  .border-bottom-radius(@panel-border-radius);
}


// For Default Panels
.panel-body {padding:20px}
.panel-heading + .panel-body {
  border: none; padding: 20px 0;
  .border-top-radius(0);
}

.panel-body + .panel-footer {
  padding: 10px 0 0;
  border-top: 1px solid @panel-inner-border;
  color: @panel-inner-border;
}


// Tabs for Default Styles
.options .nav-tabs {
  // line-height: 1;
  li {
    a {padding: 0px 10px; font-size: @font-size-base; line-height: @line-height-tabs-color}
    a:hover {background:none;}
  }
  border-bottom: 0;
  >li>a {margin-right:0;}
}


// Icon Highlights
.icon-highlight {
  font-size: 14px;
  top: -1px;
  padding: 5px 0px;
  height: 25px;
  width: 25px;
  text-align: center;
  background: rgba(0, 0, 0, 0.15);
  border-radius: @border-radius-small;
  margin: 5px 3px 5px -3px;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

// Icon Scale
.icon-scale {font-size:14px;}
.icon-scale-sm {font-size:13px;}
.icon-gray {color: rgba(0, 0, 0, 0.5);}

// Colors
.icon-highlight-info {color: #fff !important; background: @brand-info;}
.icon-highlight-primary {color: #fff !important; background: @brand-primary;}
.icon-highlight-danger {color: #fff !important; background: @brand-danger;}
.icon-highlight-success {color: #fff !important; background: @brand-success;}
.icon-highlight-inverse {color: #fff !important; background: @brand-inverse;}
.icon-highlight-orange {color: #fff !important; background: @brand-orange;}
.icon-highlight-midnightblue {color: #fff !important; background: @brand-midnightblue;}
.icon-highlight-indigo {color: #fff !important; background: @brand-indigo;}
.icon-highlight-green {color: #fff !important; background: @brand-green;}
.icon-highlight-sky {color: #fff !important; background: @brand-sky;}
.icon-highlight-warning {color: #fff !important; background: @brand-warning;}
.icon-highlight-purple {color: #fff !important; background: @brand-purple;}


// Contextual variations

.panel-primary {
  .panel-variated(@panel-primary-text, @panel-primary-heading-bg, @panel-primary-border);
}

.panel-success {
  .panel-variated(@panel-success-text, @panel-success-heading-bg, @panel-success-border);
}

.panel-warning {
  .panel-variated(@panel-warning-text, @panel-warning-heading-bg, @panel-warning-border);
}

.panel-danger {
  .panel-variated(@panel-danger-text, @panel-danger-heading-bg, @panel-danger-border);
}

.panel-info {
  .panel-variated(@panel-info-text, @panel-info-heading-bg, @panel-info-border);
}

.panel-inverse {
  .panel-variated(@panel-inverse-text, @panel-inverse-heading-bg, @panel-inverse-border);
}

// custom panels

.panel-brown {
  .panel-variated(#fff, @brand-brown, @brand-brown);
}
.panel-indigo {
  .panel-variated(#fff, @brand-indigo, @brand-indigo);
}
.panel-orange {
  .panel-variated(#fff, @brand-orange, @brand-orange);
}
.panel-sky {
  .panel-variated(#fff, @brand-sky, @brand-sky);
}
.panel-midnightblue {
  .panel-variated(#fff, @brand-midnightblue, @brand-midnightblue);
}
.panel-magenta {
  .panel-variated(#fff, @brand-magenta, @brand-magenta);
}
.panel-green {
  .panel-variated(#fff, @brand-green, @brand-green);
}
.panel-purple {
  .panel-variated(#fff, @brand-purple, @brand-purple);
}
.panel-grape {
  .panel-variated(#fff, @brand-grape, @brand-grape);
}


.panel.gray {
  .panel-variated(@text-color, #edeef0, #000);
  .panel-heading {
    border-bottom: 1px solid @border-darker;
    padding-right: 10px;
    .nav.nav-tabs li.active a {background:#fff;}
    .nav.nav-tabs li.active a:hover { background:#fff;}
    h4 {padding-left: 10px; color: #000 !important;}
    .nav-tabs li a {}
    h4>.nav.nav-tabs {right:10px;bottom:-3px;}
    h4>.nav.nav-tabs li a {line-height: 38px; padding: 0 10px; margin-top: -2px;}
  }
  .panel-body {background-color: #fff; border-top: 0px !important; border-left: 1px solid @border-darker; border-right: 1px solid @border-darker; border-bottom: 1px solid @border-darker; padding: 20px !important;}
}

.panel-gray {
  .panel-variated(#000, #edeef0, #edeef0);
  .panel-heading {
    border-top: 1px solid @border-darker !important;
    border-left: 1px solid @border-darker !important;
    border-right: 1px solid @border-darker !important;

    h4 {color: #000 !important}
    .options a {color: #000 !important}

    border-bottom:none;
    &.rounded-bottom {border-bottom: 1px solid @border-darker !important;}
  }
  .panel-body {
    border-bottom: 1px solid @border-darker !important;
    border-left: 1px solid @border-darker !important;
    border-right: 1px solid @border-darker !important;
  }
}



// Colored Panels


[class*="panel-"].panel {
  .panel-heading {
    padding: 0 10px;
    border-bottom:none;
    
    &.rounded-bottom {.border-bottom-radius(@panel-border-radius);}

    .nav.nav-tabs > li { // ALL Tabs in colored panels
      a {
        color: #fff; //fallback
        color:rgba(255,255,255,0.75);
      }
      a:hover {color: #fff;}
      &.active a {color: #000; background: #fff;}
    }

    // Options
    .options {
      // line-height: 1;
      a {color: #fff;}

      .nav-tabs {
        li, li a {border: none; font-size: @font-size-base !important;}
        li a {line-height: 35px}
        
        li.active a:hover {color: #000;}
        li a i {font-size: @font-size-base;}
      }
    }
  }

  .panel-body {
    padding: 20px;
    border-left: 1px solid @panel-inner-border;
    border-right: 1px solid @panel-inner-border;
    border-bottom: 1px solid @panel-inner-border;
    background-color: #fff;
  }
  .panel-footer {
    background-color: @panel-footer-bg;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: @panel-inner-border;
    padding: 20px 20px;
    color: darken(@gray-dark, 25%);
  }

  .panel-heading+.panel-body {border-top:none;}


  .panel-body .panel-footer {
    margin: 20px -21px -21px;
    border-top: 1px solid @panel-inner-border;
  }
}






//Heading Tabs

.panel-heading>h4>ul.nav.nav-tabs {
  position: relative;
  bottom: -3px;
  > li {
      padding-left: 0;
      >a {
        padding: 0 10px; 
        line-height: 38px;
        margin-top: -2px;
      }
    }
}



[class*="panel-"].panel {
  .panel-heading>h4 {
    color: #fff;
    >ul.nav.nav-tabs {
      bottom: -1px;
      right: 9px;
      border:none;
      > li > a {border:none;padding: 0 10px; line-height: 40px; margin-top: 0px;}
    }
  }
}

// List groups in panels
//
// By default, space out list group content from panel headings to account for
// any kind of custom content between the two.

.panel-body {
  > .list-group {
    margin: -20px;

    .list-group-item {
      border-width: 1px 0;

      // Remove border radius for top one
      &:first-child {
        .border-top-radius(0);
      }
      // But keep it for the last one
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
// Collapse space between when there's no additional content.
.panel-heading + .list-group {
  .list-group-item:first-child {
    border-top-width: 0;
  }
}

// Tables in panels
//
// Place a non-bordered `.table` within a panel (not within a `.panel-body`) and
// watch it go full width.

.panel {
  > .table {
    margin-bottom: 0;
  }
  > .panel-body + .table {
    border-top: 1px solid @table-border-color;
  }
}

// Collapsable panels (aka, accordion)
//
// Wrap a series of panels in `.panel-group` to turn them into an accordion with
// the help of our collapse JavaScript plugin.

.panel-group {
  // Tighten up margin so it's only between panels
  .panel {
    margin-bottom: 0;
    border-radius: @panel-border-radius;
    overflow: hidden; // crop contents when collapsed
    + .panel {
      margin-top: 5px;
    }
  }

  .panel-heading {
    border-bottom: 0;
    h4 a {color: #fff}
    + .panel-collapse .panel-body {
      border-top: 1px solid @panel-inner-border;
    }
  }
  .panel-footer {
    border-top: 0;
    + .panel-collapse .panel-body {
      border-bottom: 1px solid @panel-inner-border;
    }
  }

  // New subcomponent for wrapping collapsable content for proper animations
  .panel-collapse {
    .panel-body {
      border-top:none;
      .border-top-radius(0);
    }
  }
}


//Custom Panel Mixin
//-------------------

.panel-variated(@heading-text-color, @heading-bg-color, @heading-border) {
  border-color: @heading-border;
  .panel-heading {
    color: @heading-text-color;
    background-color: @heading-bg-color;
    border-color: @heading-border;
  }
  .panel-body {
    border-top: 2px solid @heading-border; 
  }
}


//Dropdowns in Panels
//--------------------
.panel .panel-heading .nav.nav-tabs > li {margin-bottom: -2px;}
.panel .panel-heading .nav.nav-tabs > li.dropdown {
  > a:hover, a:focus {color: #000; background-color: transparent;}
  &.open > a, &.open > a:hover, &.open > a:focus {color: #000; background-color: transparent;border: 1px solid transparent}
}

[class*="panel-"].panel .panel-heading .nav.nav-tabs > li {margin-bottom: -1px;}
[class*="panel-"].panel .panel-heading .nav.nav-tabs > li.dropdown {
  > a:hover, a:focus {background-color: transparent; color: #fff; color: rgba(255,255,255,0.75);}
  &.open > a, &.open > a:hover, &.open > a:focus {background-color: transparent; color: #fff; border: none;}
}

.panel-heading h4 .nav.nav-tabs .dropdown-menu {margin-top: -2px;}
.panel-heading .nav.nav-tabs > li.dropdown.open ul.dropdown-menu li a {
  color: #000;
  &:hover {background-color: #D2D3D6; border:none}
}
//navs.less
.nav-tabs .dropdown-menu {margin-top:0px;}
.nav-tabs > li > a {margin-right: 0px;}
.nav-tabs > li > a:hover {border-color: @gray-lighter;}

.nav .open > a {
    &, &:hover, &:focus {border-color:@gray-lighter;}
}

//button-groups.less

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
	border-bottom-right-radius: @border-radius-small;
	border-top-right-radius: @border-radius-small;
}

.btn.dropdown-toggle {min-width: 26px;}

.btn-group, .btn-group-vertical {
	padding: 0;
	> .btn.fc-state-active {
		z-index: 2;
	}
	.btn-group + .btn-group {
    	margin-left: 0px;
	}
}
.btn-toolbar {
	.btn-group {float: none;}
	> .btn + .btn,
	> .btn-group + .btn,
	> .btn + .btn-group,
	> .btn-group + .btn-group {margin-left: 0px;}
}
.btn-group {
	> .btn + .dropdown-toggle {
	  padding-left: 5px;
	  padding-right: 5px;
	}
	> .btn-lg + .dropdown-toggle {
	  padding-left: 15px;
	  padding-right: 15px;
	}
	&.open .dropdown-toggle {
		.box-shadow(inset 0 1px 3px rgba(0,0,0,.2));
	}
}

//breadcrumbs.less
.breadcrumb {
	margin-bottom: 0;
	> li {
		font-size: @font-size-small !important;
	}
}

//pagination.less
.pagination {
	margin: 10px 0;
	> li {
		> a, span {
			padding: 6px 15px;
		}
	}
}

//pager.less
.pager li {
	> a, span {
		padding: 6px 14px;
	}
}
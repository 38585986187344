//alerts.less
.alert .alert-link {
	font-weight: @alert-link-font-weight; color: @brand-primary !important;
}

//thumbnails.less
.thumbnail {
	margin-bottom: 0;
}

//labels.less
.label {
	padding: .09em .6em .15em;
	font-family: @label-font-family;
  font-weight: @label-font-weight;
  border-radius: @label-border-radius;
}

// Colors
.label-orange {
  .label-variant(@label-orange-bg);
}
.label-grape {
  .label-variant(@label-grape-bg);
}
.label-inverse {
  .label-variant(@label-inverse-bg);
}
.label-sky {
  .label-variant(@label-sky-bg);
}

.badge {
	padding: 4px 6px;
	font-size: 11px;
	font-family: @badge-font-family;
}

// Colors
// Contextual variations (based on label colors)

.badge-primary  {.label-variant(@label-primary-bg);}
.badge-success  {.label-variant(@label-success-bg);}
.badge-info     {.label-variant(@label-info-bg);}
.badge-warning  {.label-variant(@label-warning-bg);}
.badge-danger   {.label-variant(@label-danger-bg);}

#sidebar {
  .badge.badge-primary  {.label-variant(@label-primary-bg);}
  .badge.badge-indigo  {.label-variant(@label-indigo-bg);}
  .badge.badge-orange  {.label-variant(@label-orange-bg);}
  .badge.badge-success  {.label-variant(@label-success-bg);}
  .badge.badge-info     {.label-variant(@label-info-bg);}
  .badge.badge-warning  {.label-variant(@label-warning-bg);}
  .badge.badge-danger   {.label-variant(@label-danger-bg);}
}

//progressbars.less
.progress {height: 8px;}
.progress, .progress-bar {.box-shadow(~"none");}

//Variants
.progress-bar-inverse{
  .progress-bar-variant(@progress-bar-inverse-bg);
}

.progress-bar-primary{
  .progress-bar-variant(@progress-bar-bg);
}

.progress-bar-green {
  .progress-bar-variant(@progress-bar-green-bg);
}

.progress-bar-orange {
  .progress-bar-variant(@progress-bar-orange-bg);
}

.progress-bar-purple {
  .progress-bar-variant(@progress-bar-purple-bg);
}

.progress-bar-indigo {
  .progress-bar-variant(@progress-bar-indigo-bg);
}

.progress-striped .progress-bar {
  #gradient > .striped(rgba(255,255,255,0.35));
}

// Contextual Progress
.contextual-progress {
  margin: 20px 0;
  .progress-percentage {
    float: right;
    position: relative;
  }
  .label {
    position: absolute;
    right: 32px; top: 2px;
    padding: 3px 5px;
  }
  .progress-title {float: left;}

  .progress {
    height:2px;
    margin:5px 0 0;
  }
}
.contextual-progress:first-child{margin-top: 0;}

//input-groups.less
.input-group-addon {
	padding: @padding-base-vertical 5px;
	min-width: 44px;
}
.input-group-btn > .btn {
	+ .dropdown-toggle {
    	padding-left: 5px;
    	padding-right: 5px;
  	}
}


//carousel.less
.carousel-caption h3 {
  color: @carousel-heading-color;
}
//dropdowns.less

.caret {
  -webkit-font-smoothing: antialiased;
  display: inline;
  margin-left: 2px;
  border-top: 0; border-right: 0;border-left: 0; border-bottom: 0;
  vertical-align: top;
	&:before {
    	content: "\f0d7"; 
    	font-family: FontAwesome;
    	font-style: normal;
    	font-weight: normal;
    	text-decoration: inherit;
	
    	font-size: 14px;
	}
}

.dropdown-menu {
	left: auto; right: 0;
	margin-top: 2px;
	.box-shadow(0 2px 10px rgba(0,0,0,.1));
	background-color: @dropdown-fallback-bg;
	background-color: @dropdown-bg;
	&.pull-left {
		left: 0; right: auto;
	}
	>li>a {
		padding: 5px 15px;
	}
	&.arrow:before {
		position: absolute; 
		top: -8px; 
		right: 11px; 
		display: inline-block; 
		border-right: 10px solid transparent; 
		border-bottom: 10px solid #fff; 
		border-left: 10px solid transparent; 
		content: '';
	}
}

//wells.less

.well {
	padding: 19px;
	blockquote {
    	border-color: #e6e7e8;
    }
    &.well-transparent  {
		background: none;
	}
}


html {
  &.ribbon_dev,
  &.ribbon_preprod {
    &:after{
      content: "dev";
      width: 200px;
      padding: 16px;
      position: absolute;
      text-align: center;
      color: #f0f0f0;
      position: fixed;
      z-index: 9999;
    }

    &.top-right:after {
      top: 25px;
      right: -50px;
      transform: rotate(45deg);
    }

    &.top-left:after {
      top: 25px;
      left: -50px;
      transform: rotate(-45deg);
    }

    &.bottom-right:after {
      bottom: 25px;
      right: -50px;
      transform: rotate(-45deg);
    }

    &.bottom-left:after {
      bottom: 25px;
      left: -50px;
      transform: rotate(45deg);
    }
  }

  &.ribbon_dev {
    &:after{
      content: "dev";
      background-color: #2c7;
    }
  }

  &.ribbon_preprod {
    &:after{
      content: "preprod";
      background-color: #e43;
    }
  }
}

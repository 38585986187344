@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    src: url('@{icon-font-path}EOT/SourceSansPro-Light.eot') format('embedded-opentype'),
         url('@{icon-font-path}WOFF/OTF/SourceSansPro-Light.otf.woff') format('woff'),
         url('@{icon-font-path}OTF/SourceSansPro-Light.otf') format('opentype'),
         url('@{icon-font-path}TTF/SourceSansPro-Light.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    src: url('@{icon-font-path}EOT/SourceSansPro-Regular.eot') format('embedded-opentype'),
         url('@{icon-font-path}WOFF/OTF/SourceSansPro-Regular.otf.woff') format('woff'),
         url('@{icon-font-path}OTF/SourceSansPro-Regular.otf') format('opentype'),
         url('@{icon-font-path}TTF/SourceSansPro-Regular.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Sans Pro';
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    src: url('@{icon-font-path}EOT/SourceSansPro-Semibold.eot') format('embedded-opentype'),
         url('@{icon-font-path}WOFF/OTF/SourceSansPro-Semibold.otf.woff') format('woff'),
         url('@{icon-font-path}OTF/SourceSansPro-Semibold.otf') format('opentype'),
         url('@{icon-font-path}TTF/SourceSansPro-Semibold.ttf') format('truetype');
}

//scaffolding.less

:focus {outline:none !important;}
::-moz-focus-inner {border:0 !important;}

body {font-weight: @font-weight-base;}
a {outline: none;}
.img-rounded {border-radius: 5px;}

hr.outsider {
	margin-left: -20px;
	margin-right: -20px;
	border-top: 1px solid @border-darker;
}

//type.less
.text-primary, .text-warning, .text-danger, .text-success, .text-info {
	&:hover {color: inherit;}
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: @headings-font-family;
	font-weight: @headings-font-weight;
}

h1, h2, h3, .h1, .h2, .h3 {
	margin-top: 0;
	font-weight: 300;
}

h1 small, .h1 small { font-size: ceil(@font-size-base * 1.70); } // ~24px
h2 small, .h2 small { font-size: ceil(@font-size-base * 1.25); } // ~18px
h3 small, .h3 small,
h4 small, .h4 small { font-size: @font-size-base; }

.list-inline > li {
	&:first-child {padding-left: 0;}
}

.panel dl    {margin: 0 auto;}
.panel dl dd {margin-bottom:10px;}

blockquote {
	font-style: italic;
	border-left-width: 2px;
	&.pull-right {
		border-right-width: 2px;
	}
}

address {display: block;}

//code.less
kdb, samp {
	font-family: @font-family-monospace;
}

pre {
	margin-bottom: 20px;
	&.prettyprint {
    	margin-bottom: 10px; //@line-height-computed;
    }
}

// tables.less
// Responsive Tables
.table-responsive {
	overflow-x: auto;
}
@media (max-width: @screen-xs-max) {

	/* Wrap your tables in `.table-flipscroll` and we'll fix the header and make the tbody scrollable */
	.table-flipscroll {
		table, thead {.clearfix();}
		table, caption, td, thead, thead tr, th, tbody {display: block;}

		table { 
			width: 100%; border-collapse: collapse; border-spacing: 0; position: relative; 

			th, td {margin: 0; vertical-align: top; 
				&:last-child {border-bottom: 1px solid @table-border-color;}
			}
			td {min-height: 1.25em; text-align: left; border-left: 0; border-right: 0; border-bottom: 0;}

			thead {float: left;}
			th {text-align: left; border-bottom-width: 1px !important; border-left: 0;
				&:first-child {border-top: 1px solid transparent !important;}
			}

			tbody {width: auto; position: relative; overflow-x: auto; white-space: nowrap; 
				tr  {display: inline-block; vertical-align: top; border-left: 1px solid @table-border-color;}
			}
		}
	}

	/* Force table to not be like tables anymore */
	.table-vertical { 
		table, caption, thead, tbody, th, td, tr { 
			display: block; 
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr { 
			position: absolute;
			top: -9999px;
			left: -9999px;
		}
		tr { border: 1px solid #ccc; }

		td { 
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid @table-border-color; 
			position: relative;
			padding-left: 50% !important; 
			white-space: normal;
			text-align:left;
			&:before { 
    		    position: absolute; // Now like a table header
    		    top: 6px; // Top/left values mimic padding //
    		    left: 6px;
    		    width: 45%; 
    		    padding-right: 10px; 
    		    white-space: nowrap;
    		    text-align:left;
    		    font-weight: bold;
    		    content: attr(data-title); // Label the data
    		}
		}
	}
}

// .table-hidecolumn {
// 	@media (max-width: @screen-xs-max) {
// 		.table-hide-xs {display:none; visibility:hidden;}
// 		.table-hide-sm {display:none; visibility:hidden;}
// 		.table-hide-md {display:none; visibility:hidden;}
// 	}
// 	@media (max-width: @screen-sm-max) {
// 		.table-hide-xs {display:none; visibility:hidden;}
// 		.table-hide-sm {display:none; visibility:hidden;}
// 	}
// 	@media (max-width: @screen-md-max) {
// 		.table-hide-md {display:none; visibility:hidden;}
		
// 	}
// }

// forms.less
legend {
	padding-bottom: 5px;
	font-weight: 300;
	font-size: @font-size-h3;
}

label {margin-bottom: 10px; font-weight: normal;}

select[multiple] {
  padding: 7px !important;
}

.form-control {
	height: @input-height-base - 2;
	padding: 5px 9px;
	.box-shadow(inset 0 1px 0px rgba(0,0,0,0.015)) !important;
	select& {
		padding: 6px 9px 5px 4px;
	}
}
	
.form-group {
	margin-bottom: 20px;
	// Use row-border with a form to have both top and bottom 1px separation
	.row-border & {
		border-top: 1px solid @border-lighter;
		padding-top: 20px;
		padding-bottom: 20px;
		margin-bottom: 0;
		margin-left: -20px;
		margin-right: -20px;
		padding-left: 10px;
		padding-right: 10px;
		&:first-child {
			border-top: 0;
			padding-top: 0;
		}
	}
}

.help-block {
	margin-bottom: 0;
}

// buttons.less
.btn {
	font-family: @btn-font-family;
	border-width: 0px;
	.box-shadow(~"inset 0 0 0px 1px rgba(0,0,0,0.08)");
	&:focus {
		outline: none;
	}
	&:active, &.active, &.fc-state-active {
		.box-shadow(~"inset 0 1px 3px rgba(0,0,0,.2)");
	}
}

.btn-link, .btn-link:active {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.btn-inverse {
 .button-variant(@btn-inverse-color, @btn-inverse-bg, @btn-inverse-border);
}
.btn-white {
 .button-variant(@btn-white-color, @btn-white-bg, @btn-white-border);
}
.btn-link {
	.box-shadow(~"none");
}

// custom colors
.btn-brown {
  .button-variant(#fff, @brand-brown, @brand-brown);
}
.btn-indigo {
  .button-variant(#fff, @brand-indigo, @brand-indigo);
}
.btn-orange {
  .button-variant(#fff, @brand-orange, @brand-orange);
}
.btn-sky {
  .button-variant(#fff, @brand-sky, @brand-sky);
}
.btn-midnightblue {
  .button-variant(#fff, @brand-midnightblue, @brand-midnightblue);
}
.btn-magenta {
  .button-variant(#fff, @brand-magenta, @brand-magenta);
}
.btn-green {
  .button-variant(#fff, @brand-green, @brand-green);
}
.btn-purple {
  .button-variant(#fff, @brand-purple, @brand-purple);
}
.btn-alizarin {
  .button-variant(#fff, @brand-alizarin, @brand-alizarin);
}
.btn-block + .btn-block {
	margin-top: 10px;
}

// Alternate buttons
// --------------------------------------------------

.btn-default-alt {
  .btn-alt-states(@btn-default-bg);
  &:active, &.active {color: #000 !important;}
}

.btn-primary-alt      {.btn-alt-states(@btn-primary-bg);}
.btn-warning-alt      {.btn-alt-states(@btn-warning-bg);}
.btn-danger-alt       {.btn-alt-states(@btn-danger-bg);}
.btn-success-alt      {.btn-alt-states(@btn-success-bg);}
.btn-info-alt         {.btn-alt-states(@btn-info-bg);}
.btn-inverse-alt      {.btn-alt-states(@btn-inverse-bg);}

// custom colors
.btn-brown-alt        {.btn-alt-states(@brand-brown);}  
.btn-indigo-alt       {.btn-alt-states(@brand-indigo);}
.btn-orange-alt       {.btn-alt-states(@brand-orange);}
.btn-sky-alt          {.btn-alt-states(@brand-sky);}
.btn-midnightblue-alt {.btn-alt-states(@brand-midnightblue);}
.btn-magenta-alt      {.btn-alt-states(@brand-magenta);}  
.btn-green-alt        {.btn-alt-states(@brand-green);} 
.btn-purple-alt       {.btn-alt-states(@brand-purple);} 
.btn-alizarin-alt       {.btn-alt-states(@brand-alizarin);} 


.btn[class*="alt"] {
  padding: (@padding-base-vertical)-1 (@padding-base-horizontal)-1;  // border makes up for the paddings we are decreasing
  &.btn-label i {.rgba(black,0.02) !important;}
}
.btn-lg[class*="alt"] {
  .button-size((@padding-large-vertical)-1;( @padding-large-horizontal)-1; @font-size-large; @line-height-large; @border-radius-large);
}

.btn-sm[class*="alt"] {
  .button-size((@padding-small-vertical)-1; (@padding-small-horizontal)-1; @font-size-small; @line-height-small; @border-radius-small);
}

.btn-xs[class*="alt"] {
  .button-size((@padding-small-vertical)-1; (@padding-small-horizontal)-1; @font-size-small; @line-height-small; @border-radius-small);
  padding: 0 4px;
}



//btn-label

.btn-label {
	padding: 0 20px 0 14px !important;

	i {
		padding: 9px 12px;
		line-height: 14px;
		left: -14px;
		width: 40px;
		
		position: relative;
		display: inline-block;
		//background: rgba(0,0,0,.1);
		.rgba(black,0.1);
		border-radius: 3px 0 0 3px;
		box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.1);
	}

	&.btn-block {
		padding-top:    8px !important;
		padding-bottom: 8px !important;
		
		i 		 {top: -9px;}
		i + span {left: -10px; position: relative;}
	}
	&.btn-xs {
		padding: 0 5px 0 2px !important;
		i {
			width: 24px;
			left: -3px;
			line-height: 1em;
			padding: 3px 4px;
		}
	}
	&.btn-sm {
		padding: 0 12px 0 8px !important;
		i {
			left: -8px;
			line-height: 8px;
			width: 32px;
		}
	}
	&.btn-lg {
		padding: 0 29px 0 21px !important;
		i {
			left: -21px;
			line-height: 23px;
			width: 48px;
		}
	}
}